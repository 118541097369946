import React from "react";
import {Container, Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import licon1 from './LpIcon4.svg';
import licon2 from './LpIcon5.svg';
import licon3 from './LpIcon6.svg';
import licon4 from './LpIcon7.svg';
import logoImage from './getpcLogo.png';


function BottomPage(){
    return(
        <section className="Bottomp">
            <Container>
                <div>
                    <span>
                        <img className="imgNv" src={logoImage} alt='logo'/>
                    </span>

                    <Row className="contain3">
                        <Col>

                        <p>First Floor, Shastri Complex, Gas Godam<br/>Rd, next to Central Hospital, Shiv Puram<br/>Phase -I, Kusumkhera, Bomari Tallikham,<br/>Haldwani, Uttarakhand 263139</p>
                        <p><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d565.1981533191771!2d79.50529878565857!3d29.221848522982416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a09b0ee03341d7%3A0x53f6112dec278fc9!2sGET%20Pc!5e0!3m2!1sen!2sin!4v1724413105750!5m2!1sen!2sin" 
                                    width="300%" height="300" id="map-img" className="border:0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></p>
                        <h5>Email: support@getpc.co.in</h5>
                        <h5>Phone: +919259568740, +919971591682</h5>
                        <div className=" Icons d-flex   gap-2" >
                            <Link to="https://www.facebook.com/profile.php?id=61561973894554" data-aos="flip-right"><img src={licon1} alt="" /></Link>
                            <Link to="https://www.instagram.com/getpc.co.in?igsh=MW9kcHl1ZjEzdzJxZA==" data-aos="flip-right" ><img src={licon2} alt="" /></Link>
                            <Link to="https://www.linkedin.com/company/getpc/" data-aos="flip-right" ><img src={licon3} alt="" /></Link>
                            <Link data-aos="flip-right" ><img src={licon4} alt="" /></Link>
                        </div>
                        <br/>
                        <h5>© 2024 GET PC. All Rights Reserved.</h5>
                        </Col>
                        <Col>
                        <ul>
                            <li>Team</li>
                            <li>History</li>
                            <li>Careers</li>
                        </ul>
                        </Col>
                        <Col>
                        <ul>
                            <li>Privacy Policy</li>
                            <li>Terms and Conditions</li>
                            <li>Contact Us</li>
                        </ul>
                        </Col>
                        
                    </Row>
                </div>
            </Container>
        </section>
    );
}
export default BottomPage;