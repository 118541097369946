import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/Home/Homepage';
import About from './components/About/Aboutpage';
import Blog from './components/Blogs/Blogpage';
import Contact from './components/Contact/Contactpage';
import Login from './components/Login/Loginpage';
import SignUp from './components/Login/LoginSec2/SignUp';
import NewPassword from './components/Login/LoginSec3/NewPassword';
import Portfolio from './components/Portfolio/portfoliopage';
import Service from './components/Services/Servicepage';
import Enquire from './components/Home/HomeSec2/Enquire';
import SocialMed from './components/Home/HomeSec7/SocialMed';
import img2 from '../src/components/Home/WhatsApp.svg';
import Brands from './components/Home/HomeSec8/Brands';
import Products from './components/Products/Eng&Des/Eng&Des';
// import Gaming from './components/Products/ProductSec1/Gaming';
// import Leo from './components/Products/ProductSec2/Eng&Des/Leonardo/LeonardoSeries';
// import Pica from './components/Products/ProductSec2/Eng&Des/Picasso/PicassoSeries';

function App() {
  
  return (
      <div>
        <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home/>}/>     
          <Route exact path="/about" element={<About/>}/>
          <Route exact path="/blog" element={<Blog/>}/>
          <Route exact path="/contact" element={<Contact/>} />
          <Route exact path="/login" element={<Login/>} />
          <Route exact path="/signup" element={<SignUp/>} />
          <Route exact path="/newpassword" element={<NewPassword/>} />
          <Route exact path="/portfolio" element={<Portfolio/>} />
          <Route exact path="/services" element={<Service/>} />
          <Route exact path="/enquire" element={<Enquire/>} />
          <Route exact path="/SocialMed" element={<SocialMed/>} />
          <Route exact path="/brands" element={<Brands/>} />
          <Route exact path="/products" element={<Products/>} />
          {/* <Route exact path="/Leonardo Series" element={<Leo/>} /> */}
          {/* <Route exact path="/Picasso" element={<Leo/>} /> */}
        </Routes>
        </BrowserRouter>
        <div className='whatsapp-btn'>
            <a href='https://wa.me/919259568740'><img src={img2} /></a>
            </div>
      </div>
  );
}
export default App;