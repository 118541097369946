import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Enquire from "../../../Home/HomeSec2/Enquire";

function LeonardoSeries(props) {

    const [seen, setSeen] = useState(false)

    function togglePop() {
        setSeen(!seen);
    };

    return (
        <section className="prod-content">
            <span id="sp2">Picasso Series</span>
            <div className="prod-list row row-cols-2 gx-5 gy-4 mt-3">
                {props.content.map((value) => (
                    <div>
                        <div className="prod-box col">
                            <div className="prod-items">
                                <img src={value.img} width="100%" height="450px" />
                                <div>
                                    <p>lorem dsdv dfdv vfddddddddddddddddddddd  dffffffffffffffffffffffffff vfffffffffffffffffffffff fffffffffffddd  dvffffffffffffffffffff dffffffffffffffff fffffffff  fd ffffffffff f ffdf  fd ffff
                                    </p>
                                    <Button className='service-btn  px-5' onClick={togglePop} >Enquire Now</Button>
                                    {seen ? <Enquire toggle={togglePop} /> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}
export default LeonardoSeries;    