import React from "react";
import Navbar from './HomeSec1/Navbar';
import Index from "./HomeSec2/Index";
import WhyUs from "./HomeSec3/WhyUs";
import Offering from "./HomeSec4/Offering";
import Services from "./HomeSec5/Services";
import Bottompage from './HomeLastSec/Bottompage';
import Reviews from "./HomeSec6/Reviews";
import SocialMed from "./HomeSec7/SocialMed";
import reviewData from "./HomeSec6/ReviewData";
import Brands from "./HomeSec8/Brands";

function Homepage(){

    return(
        <div className="AppHome">
            <Navbar/>
            <Index/>
            <WhyUs/>
            <Services/>
            <Brands/>
            <Offering/>
            <Reviews content = {reviewData} />
            <SocialMed/>
            <Bottompage/>
        </div>
    );
}
export default Homepage;