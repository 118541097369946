import img1 from '../PC1.jpg';


const blogData= [
    {
        img: img1,
    },
    {
        img: img1,
    }
    
]
export default blogData;