import img1 from '../PC1.jpg';


const blogData= [
    {
        img: img1,
        title: "Why is it still worth it to build your own PC in 2024?",
        date: "10 August 2024"
    },
    {
        img: img1,
        title: "Greater Amberjack",
        date: "5 July 2023"
    }
    
]
export default blogData;